import React, { useState } from 'react';
// import settings from '../../config/settings';

interface Props {}
const DemoBanner: React.FC<Props> = ({}) => {
  const [show, setShow] = useState(true);

  // adding end of life banner to the top
  const text = `⚠️ Notice: This application will no longer be supported after January 31, 2025.`;
  const text2 = `Please save any important property details or tenant information before this date.`;
  const url = `https://www.jjhousing.co.uk/sanctuary/`;
  const eolBanner = (
    <div className='sticky top-0 z-50 bg-red w-full px-10 py-3 flex flex-col md:flex-row gap-3 items-start md:items-center'>
      <p className='text-start font-semibold text-lg text-white'>
        {text}
        <br />
        {text2}
      </p>
      <div className='flex gap-4 items-center ml-0 md:ml-auto shrink-0'>
        <a
          className='underline block text-white font-medium decoration-white'
          style={{ flexShrink: '0', textDecorationColor: 'white' }}
          href={url}
        >{`Learn More`}</a>
        <span
          className='underline shrink-0 block text-white font-medium decoration-white cursor-pointer'
          style={{ flexShrink: '0', textDecorationColor: 'white' }}
          onClick={() => setShow(false)}
        >{`Dismiss`}</span>
      </div>
    </div>
  );

  return show ? eolBanner : null;

  // const temp = false;
  // settings.configuration === 'demo';
  // if (temp) {
  //   return (
  //     <div className='sticky top-0 z-50 bg-red w-full px-10 py-3'>
  //       <p className='text-center uppercase font-semibold text-xl text-white'>
  //         {settings.demoText}
  //       </p>
  //     </div>
  //   );
  // } else {
  // }
};

export default DemoBanner;
