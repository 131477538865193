import React, { useEffect, useState } from 'react';
import logo from '../../assets/images/logo.svg';
import classnames from 'classnames';
import { useSidebar } from '../../state/SidebarProvider';
import SidebarItem from './components/SidebarItem';
import { useAuth } from '../../state/AuthProvider';
import facebookIcon from '../../assets/images/facebookIcon.png';
import instagramIcon from '../../assets/images/instagramIcon.svg';
import twitterIcon from '../../assets/images/twitterIcon.png';
import linkedInIcon from '../../assets/images/linkedInIcon.svg';
import SocialLinks from '../../types/SocialLinks';
import { useHistory } from 'react-router-dom';
import Button from '../../button';
import MenuObject from '../../types/MenuObject';
import SidebarSubMenu from './components/SidebarSubMenu';
import getMenuIcon from '../../helpers/getMenuIcon';
import escapeSpecialCharacters from '../../helpers/escapeSpecialCharacters';
import classNames from 'classnames';

interface Props {
  socialLinks?: SocialLinks;
  menuItems?: MenuObject[];
}

const Sidebar: React.FC<Props> = ({ socialLinks, menuItems }) => {
  const { isSidebarOpen, setIsSidebarOpen } = useSidebar();
  const { logout, user } = useAuth();
  const { push } = useHistory();
  const [subMenuItems, setSubMenuItems] = useState<any[]>([]);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [activeSubMenu, setActiveSubMenu] = useState<string>('');

  useEffect(() => {
    if (menuItems) {
      setSubMenuItems(
        menuItems?.map((item) => {
          return { parentUrl: item.url, children: [item, ...item.children] };
        })
      );
    }
  }, [menuItems]);

  return (
    <div
      className={classnames(
        'lg:fixed lg:h-screen lg:w-64 lg:max-w-64 lg:z-40  justify-center lg:flex flex-col lg:bg-white',
        {
          hidden: !isSidebarOpen,
          'w-full h-full z-10 absolute bg-grey pt-10': isSidebarOpen,
        }
      )}
    >
      <div className={classnames('h-24 hidden lg:flex justify-center')}>
        <img
          onClick={() => push('/')}
          src={logo}
          alt='JJH'
          className='max-w-full cursor-pointer px-10 h-full w-full'
        />
      </div>
      <div
        className={classnames(
          'flex flex-1 justify-between flex-col lg:px-10 pt-20 lg:pt-5 pb-6 max-w-full lg:bg-white lg:overflow-y-auto',
          {
            ' bg-grey': isSidebarOpen,
          }
        )}
      >
        <div
          className={classNames('py-4 lg:hidden', {
            hidden: !isSubMenuOpen,
          })}
        >
          <SidebarSubMenu
            setIsSubMenuOpen={setIsSubMenuOpen}
            menuItems={subMenuItems}
            activeSubMenu={activeSubMenu}
          />
        </div>

        <div className={classNames('lg:block', { hidden: isSubMenuOpen })}>
          {menuItems?.map((item, i) => {
            if (user?.isPotentialTenant) {
              return item.url === '/' || item.url === '/payments' ? (
                <SidebarItem
                  name={escapeSpecialCharacters(item.title)}
                  key={`SideBar_SideBarItem_SubItem_${i}`}
                  link={item.url}
                  icon={getMenuIcon(item.url)}
                  className='border-white border-b-2 pl-7 lg:pl-0 py-4 cursor-pointer font-semibold'
                  openSubMenu={() => {
                    setIsSubMenuOpen(true);
                    setActiveSubMenu(item.url);
                  }}
                />
              ) : null;
            }

            return (
              <SidebarItem
                key={`SideBar_SideBarItem_${i}`}
                name={escapeSpecialCharacters(item.title)}
                link={item.url}
                icon={getMenuIcon(item.url)}
                className='border-white border-b-2 pl-7 lg:pl-0 py-4 cursor-pointer font-semibold'
                openSubMenu={() => {
                  setIsSubMenuOpen(true);
                  setActiveSubMenu(item.url);
                }}
              />
            );
          })}
        </div>

        <div className='flex flex-col justify-between px-7 lg:px-0'>
          <div className='flex flex-col'>
            <Button
              onClick={() => {
                setIsSidebarOpen(false);
                push('/astraline');
              }}
              className='btn-tertiary mb-0 h-8 text-left'
            >
              Astraline Services
            </Button>
            <Button
              onClick={() => {
                setIsSidebarOpen(false);
                push('/terms-and-conditions');
              }}
              className='btn-tertiary my-0 h-8 text-left'
            >
              Terms and Conditions
            </Button>
            <Button
              onClick={() => {
                setIsSidebarOpen(false);
                push('/cookie-policy');
              }}
              className='btn-tertiary my-0 h-8 text-left'
            >
              Cookie policy
            </Button>
            <Button
              onClick={() => {
                push('/privacy-policy');
                setIsSidebarOpen(false);
              }}
              className='btn-tertiary my-0 h-8 text-left'
            >
              Privacy Statement
            </Button>
            <Button
              onClick={() => logout()}
              className='btn-tertiary mt-0 h-8 text-left'
            >
              Logout
            </Button>
          </div>
          <div className='flex flex-col'>
            <Button
              className='btn-tertiary mb-0 text-left h-8'
              onClick={() =>
                window.open('https://www.jjhousing.co.uk', '_blank')
              }
            >
              www.jjhousing.co.uk
            </Button>
            <Button
              className='btn-tertiary my-0 text-left h-8'
              onClick={() =>
                window.open('https://www.astraline.co.uk', '_blank')
              }
            >
              www.astraline.co.uk
            </Button>
            <span className='text-xs pt-2'>
              © {new Date().getFullYear()} Johnnie Johnson Housing Ltd
            </span>
            <span className='text-xs pt-2'>VAT Registration No. 570718238</span>
          </div>

          <div className='lg:hidden flex-row  flex pt-4 '>
            <a href={socialLinks?.facebook} target='_blank' rel='noreferrer'>
              <img
                src={facebookIcon}
                className='h-6 pr-2  cursor-pointer'
                alt='Facebook'
              />
            </a>
            <a href={socialLinks?.twitter} target='_blank' rel='noreferrer'>
              <img
                src={twitterIcon}
                className='h-6 px-2  cursor-pointer'
                alt='Twitter'
              />
            </a>
            <a href={socialLinks?.linkedin} target='_blank' rel='noreferrer'>
              <img
                src={linkedInIcon}
                className='h-6 px-2  cursor-pointer'
                alt='LinkedIn'
              />
            </a>
            <a href={socialLinks?.instagram} target='_blank' rel='noreferrer'>
              <img
                src={instagramIcon}
                className='h-6 pl-2  cursor-pointer'
                alt='Instagram'
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
